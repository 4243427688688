/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('#navbarResponsive').on('shown.bs.collapse', function () {
          $('button.navbar-toggler.ready').removeClass('ready');
        });

				$('.content-info .widget').matchHeight();

				$('.team-single header').matchHeight();

				$('.table th span').matchHeight();

        $('.slide-group').slick({
          dots: true,
          infinite: true,
          autoplay: false
        });

				if ($(document).height() > 3200) {
  				$('#return-to-top').addClass('show');
				}
        $('#return-to-top').on('click', function (e) {
          e.preventDefault();
          $('html,body').animate({
            scrollTop: 0
          }, 700);
        });

        $('#site-search').keyup(function() {
          var empty = false;
          $('#site-search').each(function() {
            if ($(this).val() == '') empty = true;
          });
          if (empty) $('#SearchSubmit').attr('disabled', 'disabled');
          else $('#SearchSubmit').removeAttr('disabled');
        });

        // Select all links with hashes
        $('a[href*="#"]')
          // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href="#0"]')
          .not('.accordion-viewer')
          .click(function(event) {
            // On-page links
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000, function() {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  }
                });
              }
            }
          });


        // EXTERNAL LINKS open in new window
        $('a').each(function() {
				   var a = new RegExp('/' + window.location.host + '/');
				   if (!a.test(this.href)) {
				   	$(this).attr("rel","external").attr("target","_blank");
				   }
				});

        // TEAM links for entire div area
				$(".team-member").click(function() {
          window.location = $(this).find("article a.btn").attr("href");
          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
				$('.questions .question article').matchHeight();
				$('.questions .question').matchHeight();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // timeline page
    'timeline': {
      init: function() {
        // JavaScript to be fired on the home page
        $( "section.timeline div.timeline-item.decorated p img" ).wrap( "<figure class='decor'></figure>" );
        $( "section.timeline div.timeline-item.decorated figure.decor" ).append( "<span class='top-left'></span><span class='btm-right'></span>" );
        const josh = new Josh();

        var elementPosition = $('#timeline').offset();

        $(window).scroll(function(){
          if($(window).scrollTop() > (elementPosition.top)){
            $('a.backto40').css('position','fixed').css('top','200px');
            $('a.backto40-sm').css('position','fixed').css('bottom','0');
          } else {
            $('a.backto40').css('position','absolute');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    'blog': {
      init: function() {
				$('.events .event article .event-wrap').matchHeight();
				$('.events .event article .event-wrap date').matchHeight();
				$('.events .event article .event-wrap h3').matchHeight();
				$('.events .event article').matchHeight();
      }
    },

    'single_post': {
      init: function() {
        $('.nav-primary .nav-item-17').addClass('active');
      }
    },

    'single_team': {
      init: function() {
        $('.nav-primary .nav-item-20').addClass('active');
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
